import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecuredSigningActive } from '@resursbank/ya-react-lib';

const mapStateToProps = state => {
    return {
        env: state.info.env,
    };
};

function Signing({ env }) {
    const data = React.useMemo(() => {
        const isProd = env === 'prod';

        return {
            keycloakData: {
                url: isProd
                    ? 'https://keycloak.yabank.c.bitbit.net'
                    : 'https://keycloak-test.yabank.c.bitbit.net',
                realm: 'kunder',
                clientId: 'forside_implicit',
            },
            signingActiveData: {
                fullTermsAndConditionsLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Fullstendige-vilkar-ya-betalingsforsikring-for-lan&land=NO&language=no',
                insuranceTermsLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Fullstendige-vilkar-ya-betalingsforsikring-for-lan&land=NO&language=no',
                termsOfPurchaseLink:
                    'https://documenthandler.resurs.com/Dokument.pdf?customerType=NATURAL&docType=Forkjopsinformasjon-ya-betalingsforsikring-for-lan&land=NO&language=no',
                cmsDomain: isProd
                    ? 'https://cmshelper-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                    : 'https://cmshelper-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
                sblDomain: isProd
                    ? 'https://sblbin-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
                    : 'https://sblbin-cust-yabank-stage.apps.okd.paas.h.bitbit.net',
                creditorServiceDomain: isProd
                    ? 'https://creditorservice-yabank-prod.apps.redpill-linpro.com'
                    : 'https://creditorservice-yabank-stage.apps.redpill-linpro.com',
            },
        };
    }, [env]);
    return <SecuredSigningActive {...data} />;
}

Signing.propTypes = {
    env: PropTypes.string,
};

export default connect(mapStateToProps)(Signing);
